import {Pipe, PipeTransform} from '@angular/core';

@Pipe({
  standalone: true,
  name: 'matSliderLabelPipe'
})
export class MatSliderLabelPipe implements PipeTransform {

  transform(input: number): string {
    input = transformSliderInputToCashAmount(input);

    if (input < 1000000) {
      return "CHF " + input.toString().replace(/\B(?=(\d{3})+(?!\d))/g, "'");
    } else {
      return "CHF " + (input / 1000000).toString() + " Mio.";
    }
  }
}

/**
 * {@link transformSliderInputToCashAmount} and {@link transformCashAmountToSliderValue} are two helper functions facilitating the customized
 * scale of the mat slider in the marketplace. The logic follows from the slider implementation with 49 steps with step
 * size 1. steps 1-20 represent the 50k steps from 50k to 1Mio. while the following 29 steps are used for 1 Mio to 30 Mio
 * with steps of 1 Mio each.
 */
export function transformSliderInputToCashAmount(input: number) {
  const lowThreshold = 19;
  const lowStep = 25000;
  const highStep = 100000;
  const baseline = 50000;
  const customStepAt = 500000;

  if (input <= lowThreshold) {
    return  (input-1) * lowStep + baseline;
  } else {
    return (input - lowThreshold) * highStep + customStepAt;
  }
}

export function transformCashAmountToSliderValue(scaledValue: number): number {
  const lowThreshold = 19;
  const highThreshold = 500000;
  const lowStep = 25000;
  const highStep = 100000;

  if (scaledValue <= highThreshold) {
    return scaledValue / lowStep - 1;
  } else {
    return lowThreshold + ((scaledValue-highThreshold) / highStep);
  }
}
