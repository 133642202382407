import {Injectable} from '@angular/core';
import {BreakpointObserver, BreakpointState} from '@angular/cdk/layout';
import {filter, map, mergeAll, Observable, of, takeLast, takeWhile, tap} from 'rxjs';

@Injectable({
  providedIn: 'root'
})
/**
 * Display service that can be used to distinguish between several breakpoints
 * defined in {@link DisplayBreakpoint}. It is recommended to inject this service
 * as a public service in a component and then utilize the async pipe for comparing
 * values emitted by {@link dimension} with enum values
 * in {@link DisplayDimension} directly in the template, e.g.: <br>
 * <div *ngIf="(displayService.dimension | async) >= displayService.DisplayDimension.MD">...</div><br>
 * For usages in the .ts file it is recommended to subscribe to the {@link dimension} observable.
 * For use cases where asynchronously obtaining the current display dimension is not possible, obtain
 * the current dimension via {@link currentDim}, however note that the value obtained by currentDim is
 * not guaranteed to be detected by the change detector.
 */
export class DisplayService {

  /**
   * Returns a boolean describing if the display is currently loaded,
   * i.e. if the template has yet only been rendered server-side, this
   * returns false, otherwise true. Can be used e.g. for rendering a spinner.
   */
  get isDisplayLoaded(): boolean { return this._isDisplayLoaded; }
  private _isDisplayLoaded = false;

  /**
   * Returns the current display dimension as {@link DisplayDimension} or undefined if
   * the view has not yet been rendered client-side. Note that changes of the current display
   * breakpoint dimension are not guaranteed to be change-detected right away by the change
   * detector, i.e. use this only if it is not possible to obtain the current dimension
   * asynchronously by subscribing to the {@link dimension} observable.
   *
   */
  get currentDim(): DisplayDimension | undefined { return this._currentDim; }
  private _currentDim: DisplayDimension | undefined = undefined;

  /**
   * Observable that returns the ordinal enum value of the display breakpoint
   * currently applied as {@link DisplayDimension}.
   */
  readonly dimension: Observable<DisplayDimension>;

  /**
   * Constant that only serves the purpose of comparing values emitted from
   * {@link dimension} with the display dimension enums.
   * Describes the breakpoints defined in {@link DisplayBreakpoint}.
   */
  readonly DisplayDimension = DisplayDimension;

  constructor(private _breakpointObserver: BreakpointObserver) {
    this.dimension = this.breakpoint();
    this._breakpointObserver.observe('(min-width: 0px)')
      .pipe(takeWhile((state: BreakpointState) => !state.matches, true), takeLast(1))
      .subscribe(() => this._isDisplayLoaded = true);
  }

  private breakpoint(): Observable<DisplayDimension> {
    const xs: Observable<DisplayDimension | undefined> = this._breakpointObserver.observe(DisplayBreakpoint.XS)
      .pipe(map((state: BreakpointState) => state.matches ? DisplayDimension.XS : undefined));
    const sm: Observable<DisplayDimension | undefined> = this._breakpointObserver.observe(DisplayBreakpoint.SM)
      .pipe(map((state: BreakpointState) => state.matches ? DisplayDimension.SM : undefined));
    const md: Observable<DisplayDimension | undefined> = this._breakpointObserver.observe(DisplayBreakpoint.MD)
      .pipe(map((state: BreakpointState) => state.matches ? DisplayDimension.MD : undefined));
    const lg: Observable<DisplayDimension | undefined> = this._breakpointObserver.observe(DisplayBreakpoint.LG)
      .pipe(map((state: BreakpointState) => state.matches ? DisplayDimension.LG : undefined));
    const xl: Observable<DisplayDimension | undefined> = this._breakpointObserver.observe(DisplayBreakpoint.XL)
      .pipe(map((state: BreakpointState) => state.matches ? DisplayDimension.XL : undefined));
    const xxl: Observable<DisplayDimension | undefined> = this._breakpointObserver.observe(DisplayBreakpoint.XXL)
      .pipe(map((state: BreakpointState) => state.matches ? DisplayDimension.XXL : undefined));

    return of(xs, sm, md, lg, xl, xxl)
      .pipe(
        // Flatten the separate breakpoint observables into a single observable
        mergeAll(),
        // Filter the undefined values emitted to ensure only actual DisplayDimensions get emitted
        // Note: need to make explicit undefined check here since dim can possibly have value 0 for dim XS
        filter((dim: DisplayDimension | undefined) => dim !== undefined), tap(() => this._isDisplayLoaded = true),
        // Update current dimension if new breakpoint gets emitted
        tap((dim: DisplayDimension) => this._currentDim = dim)
      );
  }

}

/**
 * Custom Breakpoints that can be used for angular cdk's {@link BreakpointObserver}.
 */
enum DisplayBreakpoint {
  XS = '(max-width: 576px)',
  SM = '(min-width: 576.01px) and (max-width: 768px)',
  MD = '(min-width: 768.01px) and (max-width: 992px)',
  LG = '(min-width: 992.01px) and (max-width: 1200px)',
  XL = '(min-width: 1200.01px) and (max-width: 1400px)',
  XXL = '(min-width: 1400.01px)'
}

/**
 * Constants describing the breakpoints defined in {@link DisplayBreakpoint}.
 */
enum DisplayDimension {
  XS,
  SM,
  MD,
  LG,
  XL,
  XXL
}
