import {Component, EventEmitter, inject, Input, OnDestroy, Output, ViewChild} from "@angular/core";
import {InvestmentStrategy_GetDto, WeightedAssetCategory} from "../../../models/marketframe.model";
import {MarketframeService} from "../../../services/marketframe.service";
import {TranslocoDirective, TranslocoService} from "@jsverse/transloco";
import {debounceTime, fromEvent, skip, Subject, takeUntil} from "rxjs";
import {BaseChartDirective} from "ng2-charts";
import {Chart, ChartDataset, ChartOptions, Plugin, registerables} from "chart.js";

import {
  ChartColors,
  doughnutCenterLabelPlugin,
  doughnutLabelStrokePlugin,
  getChartColors,
  getColorForCategory,
  matchLabelToCategoryToColorEnum,
  splitWordsForChartLabel
} from "../../../utils/applicaiton.utils";
import ChartDataLabels, {Context} from "chartjs-plugin-datalabels";
import {
  MatCell,
  MatCellDef,
  MatColumnDef,
  MatHeaderCell,
  MatHeaderCellDef,
  MatHeaderRow,
  MatHeaderRowDef,
  MatRow,
  MatRowDef,
  MatTable
} from "@angular/material/table";
import {PlatformService} from "../../../services/platform.service";

@Component({
  selector: 'app-chart-table-displayer',
  templateUrl: './chart-table-displayer.component.html',
  standalone: true,
  imports: [
    BaseChartDirective,
    MatTable,
    MatColumnDef,
    MatHeaderCell,
    MatCell,
    MatHeaderRow,
    MatRow,
    MatHeaderCellDef,
    TranslocoDirective,
    MatCellDef,
    MatHeaderRowDef,
    MatRowDef
  ],
  styleUrls: ['./chart-table-displayer.component.scss']
})
export class ChartTableDisplayerComponent implements OnDestroy {

  @Output() switchToIndividual = new EventEmitter<boolean>();
  @ViewChild('doughnutChart', {read: BaseChartDirective}) doughnutChart: BaseChartDirective;
  @Input() showStandardToIndividual = false;
  @Input() pkName: string | null;
  @Input() set marketplaceAllocationStrategy(selectedPresetStrategy: InvestmentStrategy_GetDto) {
    this._marketplaceAllocationStrategy = selectedPresetStrategy;
    const assetCategories = selectedPresetStrategy.weightedAssetCategories.map<string>(val => val.category);

    const { doughnutColors, doughnutTextColors } = matchLabelToCategoryToColorEnum(assetCategories, this.chartColors);
    this.doughnutColors = doughnutColors;
    this.doughnutTextColors = doughnutTextColors;
    this.setChartData(selectedPresetStrategy);
  }

  get marketplaceAllocationStrategy() {
    return this._marketplaceAllocationStrategy;
  }

  private readonly _destroy$ = new Subject<void>();

  private ms = inject(MarketframeService);
  private ts = inject(TranslocoService);
  protected ps = inject(PlatformService);

  private _marketplaceAllocationStrategy: InvestmentStrategy_GetDto;

  chartLabels: string[];
  chartColors: ChartColors;
  chartDatasets: ChartDataset<'doughnut'>[];
  chartOptions: ChartOptions<'doughnut'>;
  chartPlugins: Plugin<'doughnut'>[];

  private readonly chart_font_size = 12;
  private doughnutColors: string[];
  private doughnutTextColors: string[];

  constructor() {
    Chart.register(ChartDataLabels);
    Chart.register(...registerables);
    this.initChartNecessities();

    this.ps.execIfBrowser(() => {
      // ensure responsiveness of chart while not over-firing resize event - calling handleResize
      fromEvent(window, 'resize')
        .pipe(debounceTime(50), takeUntil(this._destroy$))
        .subscribe(() => this.handleResize());

      this.handleResize();
    });
  }

  ngOnDestroy(): void {
    this._destroy$.next();
    this._destroy$.complete();
  }


  /**
   * Sets values in {@link chartLabels}, {@link chartDatasets}, {@link chartOptions}
   * according to the specified {@link InvestmentStrategy_GetDto}.
   * @param selection The {@link InvestmentStrategy_GetDto} for which the chart has to be rendered
   * @private
   */
  private setChartData(selection: InvestmentStrategy_GetDto): void {
    const lang = this.ts.getActiveLang() as 'de'|'fr'|'en';
    this.chartDatasets[0].data = selection.weightedAssetCategories.map<number>((pair: WeightedAssetCategory) => pair.weight);
    this.chartDatasets[0].datalabels.backgroundColor = this.doughnutColors;
    this.chartDatasets[0].datalabels.color = this.doughnutTextColors;
    this.chartDatasets[0].backgroundColor = () => (this.doughnutColors as unknown as string);
    this.chartLabels = selection.weightedAssetCategories.map<string>((pair: WeightedAssetCategory) => pair.weight? this.ts.translate(`assetClasses.${pair.category}`): null);
    this.chartOptions.plugins.title.text = this.pkName? this.pkName: this.marketplaceAllocationStrategy.names[lang].split( ' ');
    if (this.doughnutChart) {
      this.doughnutChart.chart.update();
    }
  }

  // protected switchFromStandardToIndividual() {
  //   this.ms.switchingSelectedStrategyToIndividual = true;
  //   this.switchToIndividual.emit(true);
  // }


  // ####################################################################################################################
  // ############################################## Initialization methods ##############################################
  // ####################################################################################################################

  /**
   * Initializes the objects relevant for the doughnut chart if {@link MarketframeService} and
   * {@link TranslocoService} are available. Otherwise, returns without changes. Namely, it initializes
   * {@link chartLabels}, {@link chartDatasets}, {@link chartOptions}
   * @private
   */
  private initChartNecessities(): void {
    this.chartColors = getChartColors();

    this.chartDatasets = [{
      data: [],
      datalabels: {
        borderColor: "black",
        borderRadius: 10,
        offset: 20,
        font: {size: this.chart_font_size},
        anchor: 'end',
        align: 'end',
        padding: 5,
        display: () => "auto",
        formatter: (_: number, context: Context) => splitWordsForChartLabel(context)
      }
    }];
    this.chartOptions = {
      aspectRatio: 1,
      layout: {
        padding: {
          top: 30,
          bottom: 30
        }
      },
      elements: {arc: {borderWidth: 0}},
      responsive: true,
      maintainAspectRatio: false,
      cutout: '80%',
      radius: '75%',
      onResize(chart: Chart) {
        chart.update("resize");
      },
      animation: {
        animateScale: false,
        animateRotate: true,
        onProgress: animationEvent => {
          if ((animationEvent.currentStep > 830 && animationEvent.currentStep < 850)) {
            animationEvent.chart.update('default');
          }
        },
      },
      plugins: {
        title: {
          text: ''
        },
        tooltip: {
          callbacks: {
            title: () => '',
            label: (tooltipItem) => tooltipItem.label + ': ' + tooltipItem.formattedValue + '%'
          },
        },
      },
    };
    this.chartPlugins = [doughnutCenterLabelPlugin, doughnutLabelStrokePlugin];
    // Unclean workaround for translating the chartLabels since transloco's langChanges$
    // subscription emits when the new lang file starts being loaded, not when it has
    // finished loading. Also, we cannot use transloco's selectTranslateObject() method
    // on the 'assetClasses' key, since those attributes' values are transpiled with
    // interpolation values being translation keys themselves. Hence, we leverage the fact,
    // that selectTranslate() emits only initially and after the new language file has been loaded
    // after a language change.
    this.ts.selectTranslate(undefined)
      .pipe(
        skip(1),  // skip first since also emits initially on subscribe
        takeUntil(this.ms.teardownNotifier)
      )
      .subscribe(() => {
        if (this.marketplaceAllocationStrategy) {
          this.chartOptions.plugins.title.text = this.pkName? this.pkName: this.marketplaceAllocationStrategy.names[this.ts.getActiveLang() as 'de'| 'en'|'fr']?.split(' ');
          this.chartLabels = this.marketplaceAllocationStrategy.weightedAssetCategories.map<string>((pair: WeightedAssetCategory) => this.ts.translate(`assetClasses.${pair.category}`));
        }
      });
  }

  handleResize() {
    const innerWidth = window.innerWidth;
    const displaySetting = innerWidth < 768 ? false : 'auto';
    const newPadding = innerWidth < 768 ? 0 : 30;

    this.chartOptions.layout = {
      ...this.chartOptions.layout,
      padding: {
        top: newPadding,
        bottom: newPadding
      }
    };

    this.chartDatasets.forEach(dataset => {
      dataset.datalabels.display = displaySetting;
    });

    if (this.doughnutChart && this.doughnutChart.chart) {
      this.doughnutChart.render();
      this.doughnutChart.chart.update();
    }
  }

  protected readonly getColorForCategory = getColorForCategory;
}

