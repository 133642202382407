import {Injectable, OnDestroy} from "@angular/core";
import {
  IFramePostMessage,
  IFrameStrategyState,
  InvestmentStrategy_GetDto,
  MarketplaceInputData,
  MarketplaceOutputData
} from "../models/marketframe.model";
import {Observable, Subject} from "rxjs";
import {HttpClient} from "@angular/common/http";
import {InvestmentStrategyType, MandateType} from "../constants/application.constants";
import {environment} from "../../environments/environment";
import {PlatformService} from "./platform.service";

@Injectable()
export class MarketframeService implements OnDestroy {

  marketPlaceInputData: MarketplaceInputData;

  globalStrategies: InvestmentStrategy_GetDto[];
  swissStrategies: InvestmentStrategy_GetDto[];

  customPKStrategy: InvestmentStrategy_GetDto;

  iFrameStrategyState: IFrameStrategyState;

  error = false;
  isLoadingStrategies = false;

  constructor(private readonly _http: HttpClient, private ps: PlatformService) {
    this.init();
  }

  init() {
    this.marketPlaceInputData = new MarketplaceInputData();

    this.isLoadingStrategies = true;
    this.getInvestmentStrategies().subscribe({
      next: (strategies: InvestmentStrategy_GetDto[]) => {

        // Init investment strategies on initialization or after reset
        this.globalStrategies = [];
        this.swissStrategies = [];
        strategies.forEach((strategy: InvestmentStrategy_GetDto) => {
          if (strategy.type === InvestmentStrategyType.GLOBAL) {
            this.globalStrategies.push(strategy);
          } else if (strategy.type === InvestmentStrategyType.SWISS) {
            this.swissStrategies.push(strategy);
          }
        });
        //TODO: ERROR HANDLING

        //TODO: proper strategy
        this.customPKStrategy = this.globalStrategies[3];
        this.iFrameStrategyState = new IFrameStrategyState(this.customPKStrategy.weightedAssetCategories);
      }
    }).add(() => this.isLoadingStrategies = false);
  }

  getInvestmentStrategies(strategyType?: InvestmentStrategyType): Observable<InvestmentStrategy_GetDto[]> {
    return this._http.get<InvestmentStrategy_GetDto[]>(`${environment.webservice}/api/public/resources/investment-strategies${strategyType ? '/' + strategyType : ''}`);
  }


  get teardownNotifier(): Observable<void> {
    return this._destroy$;
  }
  private readonly _destroy$ = new Subject<void>();

  ngOnDestroy(): void {
    this._destroy$.next();
    this._destroy$.complete();
  }

  onNavigateEconomicoMarketPlace(mandate: MandateType, volume: number) {
    const outputData = new MarketplaceOutputData(mandate, volume, this.iFrameStrategyState);
    const queryParamsString = outputData.retrieveStrategyAsQueryParams();

    if (this.ps.isBrowser) {
      // we send the queryParamString via postMessage to the iframe parent
      this.sendMessageToIFrameParent({type: 'params', data: queryParamsString});
    }

  }


  sendMessageToIFrameParent(msg: IFramePostMessage): void {
    this.ps.execIfBrowser(() => {
      try {
        window.parent.postMessage(JSON.stringify(msg), environment.iframeParentOrigin);
      } catch (e: unknown) {
        console.error(e);
      }
    });
  }


}
