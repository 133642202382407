import {AssetCategory, InvestmentStrategyType, InvestorType, MandateType} from "../constants/application.constants";
import {RiskYieldResult_GetDto} from "./augur.model";

/**
 * Object that encodes input values for all input controls in the marketplace.
 */
export class MarketplaceInputData {
  implementation: {
    mandateType: MandateType;
    investmentVolume: number;
  };
  strategy: {
    investmentStrategyData: {
      type: InvestmentStrategyType,
      names: TranslationTriplet<string>
    } | undefined;
    categoryWeights: {
      -readonly [key in keyof typeof AssetCategory]: number
    };
  };

  constructor() {
    this.implementation = {
      mandateType: MandateType.PASSIVE,
      investmentVolume: 250000
    };
    this.strategy = {
      investmentStrategyData: undefined,
      categoryWeights: (Object.keys(AssetCategory) as [keyof typeof AssetCategory])
        .reduce(
          (accumulator: { [key in keyof typeof AssetCategory]: number }, categoryKey: keyof typeof AssetCategory) => ({ ...accumulator, [categoryKey]: 0 }),
          {} as { [key in keyof typeof AssetCategory]: number }
        )
    };
  }

  setStrategyFromPreset(preset: InvestmentStrategy_GetDto): void {
    if (preset) {
      this.strategy.investmentStrategyData = {
        names: preset.names,
        type: preset.type
      };
      this.setCategoryWeights(...preset.weightedAssetCategories);
    } else {
      // preset is undefined or null
      this.strategy.investmentStrategyData = undefined;
      this.resetCategoryWeights();
    }
  }

  /**
   * Sets the weights of all categories in {@link categoryWeights} according to the given
   * {@link WeightedAssetCategory}s. This method first resets all categories to 0 weights
   * and then sets the provided categories with their specified weights.
   * @param weightedCategories One or more {@link WeightedAssetCategory}s
   */
  setCategoryWeights(...weightedCategories: WeightedAssetCategory[]): void {
    this.resetCategoryWeights();
    weightedCategories.filter(weightedCategory => !!weightedCategory.category).forEach(pair => this.strategy.categoryWeights[pair.category] = pair.weight);
  }

  /**
   * Patches the weights of the categories in {@link categoryWeights} according to the given
   * {@link WeightedAssetCategory}s. This method differs from {@link setCategoryWeights} in
   * the regard, that it does not first reset all categories and then sets them, put leaves
   * already set categories unchanged and simply patches the values of the provided categories.
   * @param weightedCategories One or more {@link WeightedAssetCategory}s
   */
  patchCategoryWeights(...weightedCategories: WeightedAssetCategory[]): void {
    weightedCategories.filter(weightedCategory => !!weightedCategory.category).forEach(pair => this.strategy.categoryWeights[pair.category] = pair.weight);
  }

  /**
   * Set the weights of all categories in {@link categoryWeights} to 0.
   */
  resetCategoryWeights(): void {
    Object.keys(this.strategy.categoryWeights).forEach((key: string) => {
      this.strategy.categoryWeights[key as keyof typeof AssetCategory] = 0;
    });
  }


  /**
   * Returns this input data mapped to a {@link MarketplaceData_PostDto} object or undefined if
   * the provided input data is not sufficient to be mappable.
   */
  mapToMarketData(): MarketplaceData_PostDto | undefined {
    // Check if any property is undefined
    if (!this.implementation || !this.strategy ||
      (this.implementation.investmentVolume == undefined) ||
      !this.strategy.categoryWeights || !this.strategy.investmentStrategyData ||
      !this.strategy.investmentStrategyData.type || !this.strategy.investmentStrategyData?.names?.de ||
      !this.strategy.investmentStrategyData?.names?.en || !this.strategy.investmentStrategyData?.names?.fr) {
      return undefined;
    }

    const weightedAssetCategories: WeightedAssetCategory[] = this.nonZeroCategoryWeightPairs;
    const totalWeight = weightedAssetCategories.reduce((accumulator: number, pair: WeightedAssetCategory) => accumulator + pair.weight, 0);

    // Check if category weights sum up to 100
    if (totalWeight !== 100) {
      return undefined;
    }

    return {
      investmentVolume: this.implementation.investmentVolume,
      mandateType: this.implementation.mandateType,
      investmentStrategyNames: this.strategy.investmentStrategyData.names,
      investmentStrategyType: this.strategy.investmentStrategyData.type,
      weightedAssetCategories: weightedAssetCategories
    };
  }

  /**
   * Getter to obtain all categories in {@link categoryWeights} whose weight is not 0.
   */
  get nonZeroCategoryWeightPairs(): WeightedAssetCategory[] {
    return Object.keys(this.strategy.categoryWeights)
      .reduce(
        (acc: WeightedAssetCategory[], key: string) => {
          if (this.strategy.categoryWeights[key as keyof typeof AssetCategory]) {
            // weight is not 0, i.e. push category with its assigned weight onto array
            acc.push(new WeightedAssetCategory(AssetCategory[key as keyof typeof AssetCategory], this.strategy.categoryWeights[key as keyof typeof AssetCategory]));
            // return updated accumulated array
            return acc;
          }
          // weight is 0, i.e. return so-far accumulated array without pushing current category
          return acc;
        },
        [] as WeightedAssetCategory[]
      );
  }
}

export class TranslationTriplet<T> {
  de?: T;
  en?: T;
  fr?: T;

  constructor() {
    this.de = undefined;
    this.en = undefined;
    this.fr = undefined;
  }
}

export type InvestmentStrategy_GetDto = {
  id: number;
  names: TranslationTriplet<string>;
  type: InvestmentStrategyType;
  weightedAssetCategories: WeightedAssetCategory[];
  riskShare: number;
  riskYield: RiskYieldResult_GetDto | undefined;
}

/**
 * Object that is meant to describe an {@link AssetCategory} and the weight assigned to
 * that category by a user. I.e. For a user requested detailed analysis, the selected input values
 * encoded as a {@link MarketplaceData_PostDto} object, contains a list of the selected products corresponding to
 * an {@link AssetCategory} and having their weighting assigned by that user.
 */
export class WeightedAssetCategory {
  category: AssetCategory;
  weight: number;

  constructor(category: AssetCategory, weight: number, hedged?: boolean) {
    this.weight = weight;
    this.category = hedged && !category.endsWith('HEDGED') ? AssetCategory[`${category}_HEDGED` as keyof typeof AssetCategory] : category;
  }

}

/**
 * Input data selected at the marketplace in the frontend. Essentially
 * this model describes the necessary data for calculating a detailed analysis
 * by a certain provider.
 */
export type MarketplaceData_PostDto = {
  investmentVolume: number;
  mandateType: MandateType;
  investmentStrategyNames: TranslationTriplet<string>;
  investmentStrategyType: InvestmentStrategyType;
  weightedAssetCategories: WeightedAssetCategory[];
}


export class IFrameStrategyState {
  strategized: 'predefined' | 'individual';
  strategizedCaseData: {strategyId: number} | {weightedAssetCategories: WeightedAssetCategory[]};

  constructor(wac: WeightedAssetCategory[]) {
    this.strategized = 'individual';
    this.strategizedCaseData = {weightedAssetCategories: wac};
  }

  setStandardStrategy(strategy: InvestmentStrategy_GetDto): void {
    this.strategized = 'predefined';
    this.strategizedCaseData = {strategyId: strategy.id};
  }

  setCustomPkStrategy(strategy: InvestmentStrategy_GetDto): void {
    this.strategized = 'individual';
    this.strategizedCaseData = {weightedAssetCategories: strategy.weightedAssetCategories};
  }

}

/**
 * Object that encodes input values for all input controls in the marketplace.
 */
export class MarketplaceOutputData {
  iFrameStrategyState: IFrameStrategyState;
  implementation: {
    mandateType: MandateType;
    investorType: InvestorType;
    investmentVolume: number;
  };

  constructor(
    mandateType: MandateType,
    volume: number,
    strategy: IFrameStrategyState
  ) {

    this.implementation = {
      mandateType: mandateType,
      investorType: InvestorType.PRIVATE,
      investmentVolume: volume
    };

    this.iFrameStrategyState = strategy;
  }

  retrieveStrategyAsQueryParams(): string {
    const params = new URLSearchParams();

    params.append('strategized', this.iFrameStrategyState.strategized);
    params.set('immediateProceed', '');  // enables instant navigation to marketplace step 3 (Cost comparison). we always want this when coming from marketframe
    if (this.implementation.mandateType) params.append('mandateType', this.implementation.mandateType.toString());
    if (this.implementation.investorType) params.append('investorType', this.implementation.investorType.toString());
    if (this.implementation.investmentVolume) params.append('investmentVolume', this.implementation.investmentVolume.toString());

    const strategyId = (this.iFrameStrategyState.strategizedCaseData as {strategyId: number})?.strategyId;
    const weightedAssetCategories = (this.iFrameStrategyState.strategizedCaseData as {weightedAssetCategories: WeightedAssetCategory[]})?.weightedAssetCategories;

    if (this.iFrameStrategyState.strategized === 'predefined' && strategyId !== undefined) {
      params.append('strategyId', (this.iFrameStrategyState.strategizedCaseData as {strategyId: number})?.['strategyId'].toString(10));

    } else if (this.iFrameStrategyState.strategized === 'individual' && weightedAssetCategories !== undefined && weightedAssetCategories.length > 0) {
      weightedAssetCategories.forEach((category: WeightedAssetCategory) => {
        params.append('assetCategories', category.category);
        params.append('weights', category.weight.toString(10));
      });
    }

    return `${params.toString()}`;
  }
}

export type IFramePostMessage = {
  type: 'height',
  data: number
} | {
  type: 'params',
  data: string
};
