export enum MandateType {
  PASSIVE = 'PASSIVE',
  ESG = 'ESG'
}

export enum InvestorType {
  INSTITUTION = 'INSTITUTION',
  PRIVATE = 'PRIVATE',
  BVG = 'BVG'
}

export enum InvestmentStrategyType {
  GLOBAL = 'GLOBAL',
  SWISS = 'SWISS',
  INDIVIDUAL = 'INDIVIDUAL'
}

export enum AssetCategory {
  CASH_CHF = 'CASH_CHF',
  OBLIGATIONEN_CHF = 'OBLIGATIONEN_CHF',
  OBLIGATIONEN_CHF_INLAND = 'OBLIGATIONEN_CHF_INLAND',
  OBLIGATIONEN_CHF_AUSLAND = 'OBLIGATIONEN_CHF_AUSLAND',
  OBLIGATIONEN_FW = 'OBLIGATIONEN_FW',
  OBLIGATIONEN_USD = 'OBLIGATIONEN_USD',
  OBLIGATIONEN_EUR = 'OBLIGATIONEN_EUR',
  OBLIGATIONEN_FW_HEDGED = 'OBLIGATIONEN_FW_HEDGED',
  OBLIGATIONEN_USD_HEDGED = 'OBLIGATIONEN_USD_HEDGED',
  OBLIGATIONEN_EUR_HEDGED = 'OBLIGATIONEN_EUR_HEDGED',
  AKTIEN_SCHWEIZ = 'AKTIEN_SCHWEIZ',
  AKTIEN_WELT_EX_SCHWEIZ_DM = 'AKTIEN_WELT_EX_SCHWEIZ_DM',
  AKTIEN_NORDAMERIKA = 'AKTIEN_NORDAMERIKA',
  AKTIEN_EUROPA_EX_SCHWEIZ = 'AKTIEN_EUROPA_EX_SCHWEIZ',
  AKTIEN_PAZIFIK = 'AKTIEN_PAZIFIK',
  AKTIEN_WELT_EX_SCHWEIZ_DM_HEDGED = 'AKTIEN_WELT_EX_SCHWEIZ_DM_HEDGED',
  AKTIEN_NORDAMERIKA_HEDGED = 'AKTIEN_NORDAMERIKA_HEDGED',
  AKTIEN_EUROPA_EX_SCHWEIZ_HEDGED = 'AKTIEN_EUROPA_EX_SCHWEIZ_HEDGED',
  AKTIEN_PAZIFIK_HEDGED = 'AKTIEN_PAZIFIK_HEDGED',
  AKTIEN_WELT_EM = 'AKTIEN_WELT_EM',
  IMMOBILIEN_SCHWEIZ = 'IMMOBILIEN_SCHWEIZ',
  IMMOBILIEN_WELT = 'IMMOBILIEN_WELT',
  IMMOBILIEN_WELT_HEDGED = 'IMMOBILIEN_WELT_HEDGED',
}
