<div class="row">
  <div class="col-sm-12 col-xl-7 d-md-block chart-height">
    @if (ps.isBrowser) {
      <div class="w-auto h-100">
        <canvas [datasets]="chartDatasets"
                [labels]="chartLabels"
                [legend]="false"
                [options]="chartOptions"
                [plugins]="chartPlugins"
                [type]="'doughnut'"
                #doughnutChart
                baseChart>
        </canvas>
      </div>
    }
  </div>
  <div class="col-12 col-md-8 col-xl mx-auto mt-md-0 mt-2">
    <table [dataSource]="marketplaceAllocationStrategy.weightedAssetCategories"
           class="mb-2 strategy-overview-mat-table mat-elevation-z0" mat-table *transloco="let t; read: 'strategy'">
      <ng-container matColumnDef="category">
        <th *matHeaderCellDef mat-header-cell>{{t('investmentCategory')}}</th>
        <ng-container *transloco="let t; read: 'assetClasses'">
          <td *matCellDef="let element" mat-cell>
            <div class="d-flex align-items-center">
              <span class="color-square d-inline-block d-md-none me-2" [style.background-color]="getColorForCategory(element['category'], chartColors)"></span>
              {{t(element['category'])}}
            </div>
          </td>
        </ng-container>
      </ng-container>
      <ng-container matColumnDef="weight">
        <th *matHeaderCellDef mat-header-cell class="text-end">{{t('weight')}}</th>
        <td *matCellDef="let element" class="text-end" mat-cell> {{element.weight}}%</td>
      </ng-container>

      <tr *matHeaderRowDef="['category', 'weight']" mat-header-row></tr>
      <tr *matRowDef="let row; columns: ['category', 'weight'];" mat-row></tr>
    </table>
<!--    <div *ngIf="showStandardToIndividual" class="d-flex justify-content-end mb-2">-->
<!--      <fa-icon icon="pen-to-square" class="mat-primary-colour-500 me-1"></fa-icon>-->
<!--      <u (click)="switchFromStandardToIndividual()" class="pointer" *transloco="let t; read: 'marketplace'">{{t('toIndividualStrategy')}}</u>-->
<!--    </div>-->
  </div>
</div>
