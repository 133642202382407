import { Routes } from '@angular/router';
import {LangRouteGuard} from "./lang-route.guard";
import {IFrameWrapperComponent} from "./i-frame/i-frame-wrapper.component";

export const routes: Routes = [
  {
    path: ':lang/embed',
    canActivate: [LangRouteGuard],
    loadComponent: () => IFrameWrapperComponent
  },
  {
    path: '**',
    redirectTo: 'DE/embed',
    pathMatch: 'full'
  }
];
