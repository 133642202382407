import {ActivatedRouteSnapshot, Router, RouterStateSnapshot, UrlTree} from '@angular/router';
import {inject, Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {TranslocoService} from '@jsverse/transloco';

@Injectable({
  providedIn: 'root'
})
export class LangRouteGuard {
  private router = inject(Router);
  private translocoService = inject(TranslocoService);

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    const langParam: string | null = route.paramMap.get('lang');

    if (!langParam) {
      this.translocoService.setActiveLang('de');
      return this.router.parseUrl('DE');
    } else {
      this.translocoService.setActiveLang(langParam.toLowerCase());
      if (['de', 'en', 'fr'].includes(langParam)) {
        const childRoute = state.url.replace('/' + langParam, '');
        return this.router.parseUrl(langParam.toUpperCase() + childRoute);
      }

      if (!['DE', 'EN', 'FR'].includes(langParam)) {
        return this.router.parseUrl('DE');
      }
    }
    return true;
  }

}
