<div *transloco="let t; read: 'strategy'" class="row">
  <div class="col-12">
    <ng-container>
      <mat-tab-group [(selectedIndex)]="selectedTabIndex"
                     (selectedTabChange)="selectedTabChanged()"
                     mat-stretch-tabs="false" class="selected-strategy-tab">
        <mat-tab>
          <ng-template mat-tab-label>
            <fa-duotone-icon class="mat-primary-colour-100 me-2" [icon]="faEarthAmericas"></fa-duotone-icon>
            {{t('global')}}
          </ng-template>
          <ng-container [ngTemplateOutlet]="selectorTemplate"></ng-container>
        </mat-tab>
        <mat-tab>
          <ng-template mat-tab-label>
            <img
              class="me-2 mat-primary-colour-100"
              width="24"
              height="24"
              alt="Switzerland-Map"
              ngSrc='/assets/images/map_switzerland.svg'>
            {{t('swiss')}}
          </ng-template>
          <ng-container [ngTemplateOutlet]="selectorTemplate"></ng-container>
        </mat-tab>
      </mat-tab-group>
    </ng-container>
  </div>

  <ng-container *ngIf="selectedStrategy">
    <div class="details-container">
      <div class="col-12 col-xxl-10 mx-auto ms-0 me-0 w-100">

        <app-chart-table-displayer *ngIf="selectedStrategy"
                                   [marketplaceAllocationStrategy]="selectedStrategy"></app-chart-table-displayer>

      </div>
    </div>
  </ng-container>
</div>


<ng-template #selectorTemplate>
  <div class="row mx-0 my-4 justify-content-center overflow-hidden" *transloco="let t; read: 'strategy'">
    <div class="d-flex col-12 col-md-7 col-lg-8 justify-content-center">
      <div class="text-center mb-4 w-100">
        <p class="mb-0">{{ selectedStrategyName }}</p>
        <div class="strategy-selection-style-custom-slider">
          <div class="d-flex justify-content-center">

            @if (selectedTabIndex === 0) {
              <!-- GLOBAL slider -->
              <mat-slider class="w-100 d-flex flex-column" color="accent" (change)="communicateStandardStrategyChange()" discrete [max]="maxGlobalStrategySliderIndex" min="0" step="1" showTickMarks>
                <input matSliderThumb [(ngModel)]="selectedGlobalStrategySliderIndex" (valueChange)="setSelectedStrategy(globalStrategies[selectedGlobalStrategySliderIndex])">
                <div class="mt-auto label-container">
                  <span class="label-font float-start font-size-14px">{{ t('conservative') }}</span>
                  <span class="label-font float-end font-size-14px">{{ t('ambitious') }}</span>
                </div>
              </mat-slider>
            } @else {
              <!-- SWISS slider -->
              <mat-slider class="w-100 d-flex flex-column" color="accent" (change)="communicateStandardStrategyChange()" discrete [max]="maxSwissStrategySliderIndex" min="0" step="1" showTickMarks>
                <input matSliderThumb [(ngModel)]="selectedSwissStrategySliderIndex" (valueChange)="setSelectedStrategy(swissStrategies[selectedSwissStrategySliderIndex])">
                <div class="mt-auto label-container">
                  <span class="label-font float-start font-size-14px">{{ t('conservative') }}</span>
                  <span class="label-font float-end font-size-14px">{{ t('ambitious') }}</span>
                </div>
              </mat-slider>
            }

          </div>
        </div>
      </div>
    </div>
    <div class="d-flex col-12 col-md-5 col-lg-4 px-0 justify-content-center justify-content-md-end">
      <mat-card class="col-8 col-sm-6 col-md-12 col-xl-8 mb-1">
        <mat-card-content class="py-1">
          <div class="row">
            <mat-card-header class="justify-content-center p-0">
              <h4 class="bold colour-black">
                Augur Prognose
              </h4>
            </mat-card-header>
          </div>
          <div class="row">
            <div class="col-12">
              <app-yield-risk-displayer [riskYield]="selectedStrategy?.riskYield"></app-yield-risk-displayer>
            </div>
          </div>
        </mat-card-content>
      </mat-card>
    </div>
  </div>
</ng-template>


