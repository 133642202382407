import {Component, Input} from '@angular/core';
import {InvestmentStrategy_GetDto} from "../../../models/marketframe.model";
import {MarketframeService} from "../../../services/marketframe.service";
import {TranslocoDirective, TranslocoService} from "@jsverse/transloco";
import {NgIf, NgOptimizedImage, NgTemplateOutlet} from "@angular/common";
import {MatTab, MatTabGroup, MatTabLabel} from "@angular/material/tabs";
import {FaIconComponent, FontAwesomeModule} from "@fortawesome/angular-fontawesome";
import {ChartTableDisplayerComponent} from "../chart-table-displayer/chart-table-displayer.component";
import {MatSlider, MatSliderThumb} from "@angular/material/slider";
import {FormsModule} from "@angular/forms";
import {MatCard, MatCardContent, MatCardHeader} from "@angular/material/card";
import {YieldRiskDisplayerComponent} from "../yield-risk-displayer/yield-risk-displayer.component";
import {faEarthAmericas} from "@fortawesome/pro-duotone-svg-icons";

@Component({
  selector: 'app-standard-strategy',
  standalone: true,
  imports: [
    NgIf,
    MatTabGroup,
    MatTab,
    FaIconComponent,
    FontAwesomeModule,
    NgTemplateOutlet,
    MatTabLabel,
    ChartTableDisplayerComponent,
    MatSlider,
    FormsModule,
    MatSliderThumb,
    MatCard,
    MatCardContent,
    MatCardHeader,
    YieldRiskDisplayerComponent,
    NgOptimizedImage,
    TranslocoDirective
  ],
  templateUrl: './standard-strategy.component.html',
  styleUrl: './standard-strategy.component.scss'
})
export class StandardStrategyComponent {
  @Input() toggled: boolean;

  globalStrategies: InvestmentStrategy_GetDto[];
  selectedGlobalStrategySliderIndex: number;
  maxGlobalStrategySliderIndex: number;

  swissStrategies: InvestmentStrategy_GetDto[];
  selectedSwissStrategySliderIndex: number;
  maxSwissStrategySliderIndex: number;

  selectedStrategy: InvestmentStrategy_GetDto | undefined;
  get selectedStrategyName(): string {
    const lang = this.ts.getActiveLang() as 'de'|'en'|'fr';
    return this.selectedStrategy?.names[lang];
  }

  protected selectedTabIndex: number;  // 0 corresponds to GLOBAL and 1 to SWISS strategies

  constructor(protected mfs: MarketframeService,
              private ts: TranslocoService) {
    this.init();
  }


  /**
   * Sets the {@link selectedStrategy} attribute to the specified {@link InvestmentStrategy_GetDto},
   * informs the {@link MarketframeService} about the {@link WeightedAssetCategory}s defined
   * for the {@link selectedStrategy} (state update) and sets the chart data accordingly.
   * In case of the {@link selectedStrategy} being equal to the input selection, everything
   * gets reset instead.
   * @param selection The input selection
   */
  setSelectedStrategy(selection: InvestmentStrategy_GetDto) {
    this.selectedStrategy = selection;
    this.mfs.marketPlaceInputData.setStrategyFromPreset(selection);
    this.communicateStandardStrategyChange();
    if (!selection) {
      // Also reset the category weights in the marketplace state if selected strategy is undefined
      this.mfs.marketPlaceInputData.resetCategoryWeights();
    }
  }



  // ####################################################################################################################
  // ############################################## Initialization methods ##############################################
  // ####################################################################################################################

  /**
   * Invokes all single-concern initialization methods if {@link MarketframeService} is available.
   * Otherwise, returns without any changes.
   * @private
   */
  private init(): void {
    this.globalStrategies = this.mfs.globalStrategies;
    this.swissStrategies = this.mfs.swissStrategies;

    this.selectedTabIndex = 0;  // GLOBAL strategies on init
    // Set the slider indices according to the dynamic lengths of strategies. Note, that we are not stuck with 5 strategies per tab; more could be added (or removed).
    if (this.globalStrategies.length) {
      this.selectedGlobalStrategySliderIndex = Math.floor((this.globalStrategies.length - 1) / 2);
      this.maxGlobalStrategySliderIndex = this.globalStrategies.length - 1;
      this.setSelectedStrategy(this.globalStrategies[this.selectedGlobalStrategySliderIndex]);
    }
    if (this.swissStrategies.length) {
      this.selectedSwissStrategySliderIndex = Math.floor((this.swissStrategies.length - 1) / 2);
      this.maxSwissStrategySliderIndex = this.swissStrategies.length - 1;
    }
  }


  /**
   * OnTabChange set the selected strategy according to current tab and slider index
   */
  selectedTabChanged(): void {
    let strategyAfterSwitch;
    if (this.selectedTabIndex === 0) {
      // GLOBAL tab
      strategyAfterSwitch = this.globalStrategies[this.selectedGlobalStrategySliderIndex];
    } else if (this.selectedTabIndex === 1) {
      // SWISS tab
      strategyAfterSwitch = this.swissStrategies[this.selectedSwissStrategySliderIndex];
    }
    this.setSelectedStrategy(strategyAfterSwitch);
  }

  communicateStandardStrategyChange() {
    this.mfs.iFrameStrategyState.setStandardStrategy(this.selectedStrategy);
  }

  protected readonly faEarthAmericas = faEarthAmericas;
}
