<div *transloco="let t; read: 'strategy'" [class.align-items-center]="!breakpointXsCenterAligned"
     class="d-flex flex-column align-items-sm-stretch">
  <p class="mb-0">{{t('expectation')}}</p>
  <div class="mt-2 d-flex justify-content-between">
    <ng-container
      [ngTemplateOutlet]="yieldRiskTemplate"
      [ngTemplateOutletContext]="{$implicit: {value: riskYield?.yieldTuple.value, translationKey: 'yield', tooltipKey: 'tooltipYield', iconList: riskYield?.yieldTuple.faIconTypeList}}">
</ng-container>
  </div>
  <div class="mt-2 d-flex justify-content-between">
    <ng-container
      [ngTemplateOutlet]="yieldRiskTemplate"
      [ngTemplateOutletContext]="{$implicit: {value: riskYield?.riskTuple.value, translationKey: 'risk', tooltipKey: 'tooltipRisk', iconList: riskYield?.riskTuple.faIconTypeList}}"></ng-container>
  </div>
</div>

<ng-template #yieldRiskTemplate let-data>
  <ng-container *transloco="let t; read: 'strategy'">
    <div>
      <span>
        <span class="text-decoration-underline font-size-larger">{{t(data.translationKey)}}</span>: <span
        *ngIf="data.value" class="mb-0 font-size-larger">{{ data.value | percent: '.1' }}</span>
      </span>
    </div>
    <div class="d-none d-sm-block" *ngIf="data.iconList">
      <fa-icon *ngFor="let icon of data.iconList" class="me-2 augur-icons" [icon]="icon === 'fas'? faCircle: faCircleL"></fa-icon>
    </div>
  </ng-container>
</ng-template>
