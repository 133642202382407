import {Inject, Injectable, PLATFORM_ID} from '@angular/core';
import {isPlatformBrowser} from '@angular/common';

@Injectable({
  providedIn: 'root'
})
export class PlatformService {

  private readonly _platformBrowser: boolean;

  constructor(@Inject(PLATFORM_ID) platformId: object) {
    this._platformBrowser = isPlatformBrowser(platformId);
  }

  get isBrowser(): boolean {
    return this._platformBrowser;
  }

  execIfBrowser(executable: () => void): boolean {
    if (this._platformBrowser) {
      executable();
      return true;
    }
    return false;
  }

}
