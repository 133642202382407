import { Component } from '@angular/core';
import {ChartTableDisplayerComponent} from "../chart-table-displayer/chart-table-displayer.component";
import {NgIf} from "@angular/common";
import {MarketframeService} from "../../../services/marketframe.service";
import {InvestmentStrategy_GetDto} from "../../../models/marketframe.model";

@Component({
  selector: 'app-custom-pensionfund',
  standalone: true,
    imports: [
        ChartTableDisplayerComponent,
        NgIf
    ],
  templateUrl: './custom-pensionfund.component.html',
  styleUrl: './custom-pensionfund.component.scss'
})
export class CustomPensionfundComponent {

  pkStrategy: InvestmentStrategy_GetDto;

  constructor(mfs: MarketframeService) {
    this.pkStrategy = mfs.customPKStrategy;
    mfs.iFrameStrategyState.setCustomPkStrategy(mfs.customPKStrategy);
  }

}
