import {Component, Input} from '@angular/core';
import {RiskYieldResult_GetDto} from "../../../models/augur.model";
import {NgForOf, NgIf, NgTemplateOutlet, PercentPipe} from "@angular/common";
import {TranslocoPercentPipe} from "@jsverse/transloco-locale";
import {FaIconComponent, FontAwesomeModule} from "@fortawesome/angular-fontawesome";
import {TranslocoDirective} from "@jsverse/transloco";
import {faCircle} from "@fortawesome/pro-solid-svg-icons";
import {faCircle as faCircleL} from "@fortawesome/pro-light-svg-icons";

@Component({
  selector: 'app-yield-risk-displayer',
  templateUrl: './yield-risk-displayer.component.html',
  standalone: true,
  imports: [
    NgTemplateOutlet,
    NgIf,
    TranslocoPercentPipe,
    FaIconComponent,
    NgForOf,
    TranslocoDirective,
    FontAwesomeModule,
    PercentPipe
  ],
  styleUrls: ['./yield-risk-displayer.component.scss']
})
export class YieldRiskDisplayerComponent {
  @Input() riskYield: RiskYieldResult_GetDto | undefined;
  @Input() breakpointXsCenterAligned = false;

  protected readonly faCircle = faCircle;
  protected readonly faCircleL = faCircleL;
}

