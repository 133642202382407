<div (onResize)="handleHostResize($event)" *transloco=" let t; read: 'wrapper'" [formGroup]="implementationForm">
  @if (this.mfs.isLoadingStrategies) {
    <mat-spinner diameter="60" class="mx-auto my-4"></mat-spinner>
  } @else {
    <div>
      <div class="mb-4">
        <p>
          {{t('selectStrategyPrompt')}}
        </p>
        <mat-button-toggle-group class="mb-3" [(ngModel)]="strategyToggleState" [ngModelOptions]="{standalone: true}">
          <mat-button-toggle value="customPk">
            {{t('strategiesTabs.customPk')}}
          </mat-button-toggle>
          <mat-button-toggle value="standard">
            {{t('strategiesTabs.standard')}}
          </mat-button-toggle>
        </mat-button-toggle-group>
      </div>

      <div class="mb-4">
        @if (strategyToggleState === 'customPk') {
          <app-custom-pensionfund></app-custom-pensionfund>
        } @else if (strategyToggleState === 'standard') {
          <app-standard-strategy></app-standard-strategy>
        }
      </div>

      <div class="mb-4">
        <p>
          {{t('selectAmount')}}
        </p>
        <div class="row mb-5">
            @if ((ds.dimension | async) >= ds.DisplayDimension.MD) {
              <div class="col-7">
              <mat-slider #sliderRef [ngClass]="{'accent': implementationForm.controls.investmentVolumeTextFieldControl.value >= 500000}" [disableRipple]="true" [displayWith]="slp.transform" class="mt-3 w-100"
                          color="accent" discrete max="34" min="1" step="1">
                <input matSliderThumb formControlName="investmentVolumeSliderControl">
              </mat-slider>
              <span class="adjust-mt float-start">{{t('valueMin')}}</span>
              <span class="adjust-mt shift-label-end float-end">{{t('valueMax')}}</span>
              </div>
            }
          <div class="col-5">
            <mat-form-field class="ms-lg-5">
              <input inputmode="numeric"
                     [currencyMask]="{allowNegative: false, suffix: ' CHF', prefix: '', precision:  0, thousands: '\''}"
                     currencyMask formControlName="investmentVolumeTextFieldControl" matInput>
              <mat-error>{{t('errors.investmentVolumeValueError')}}</mat-error>
            </mat-form-field>
          </div>
        </div>

      </div>

      <div class="mb-4">
        <p>
          {{t('selectStyle')}}
        </p>
        <mat-button-toggle-group class="mb-3" formControlName="mandateType">
          <mat-button-toggle [value]="MandateType.PASSIVE">
            {{t('styles.passive')}}
          </mat-button-toggle>
          <mat-button-toggle [value]="MandateType.ESG">
            {{t('styles.esg')}}
          </mat-button-toggle>
        </mat-button-toggle-group>
      </div>

      <div class="d-flex justify-content-end">
        <button mat-flat-button (click)="onSubmit()" color="primary">{{t('cont')}}</button>
      </div>

      <div style="height: 50px"></div>  <!-- set this div as pseudo-margin for the iframe to account for the threshold adjustment in finport -->
    </div>
  }
</div>
