import {inject, Injectable, isDevMode, NgModule} from "@angular/core";
import {provideTransloco, Translation, TranslocoLoader, TranslocoModule} from "@jsverse/transloco";
import {HttpClient, HttpHeaders} from "@angular/common/http";
import {environment} from "../environments/environment";
import {provideTranslocoLocaleLangMapping} from "@jsverse/transloco-locale";

@Injectable({ providedIn: 'root' })
export class TranslocoHttpLoader implements TranslocoLoader {
  private http = inject(HttpClient);

  getTranslation(lang: string) {

    const source = `${environment.clientOrigin}/assets/i18n/${lang}.json`;
    const headers = new HttpHeaders({
      'Cache-Control':  'no-cache, no-store, must-revalidate, post-check=0, pre-check=0',
      'Pragma': 'no-cache',
      'Expires': '0'
    });
    return this.http.get<Translation>(source, {headers});
  }

}

@NgModule({
  exports: [ TranslocoModule ],
  providers: [
    provideTransloco({
      config: {
        availableLangs: ['de', 'en', 'fr'],
        fallbackLang: 'de',
        defaultLang: 'de',
        prodMode: !isDevMode(),
        reRenderOnLangChange: true
      },
      loader: TranslocoHttpLoader
    }),
    provideTranslocoLocaleLangMapping({
      en: 'en-US',
      de: 'de-CH',
      fr: 'fr-FR'
    })
  ],
})
export class TranslocoRootModule {}
