import {Component, ElementRef, Renderer2, ViewChild} from '@angular/core';
import {TranslocoDirective, TranslocoPipe, TranslocoService} from "@jsverse/transloco";
import {MatButtonToggle, MatButtonToggleGroup} from "@angular/material/button-toggle";
import {CustomPensionfundComponent} from "./components/custom-pensionfund/custom-pensionfund.component";
import {StandardStrategyComponent} from "./components/standard-strategy/standard-strategy.component";
import {FormControl, FormGroup, FormsModule, ReactiveFormsModule, Validators} from "@angular/forms";
import {AsyncPipe, NgClass} from "@angular/common";
import {MatSlider, MatSliderThumb} from "@angular/material/slider";
import {MatError, MatFormField, MatSuffix} from "@angular/material/form-field";
import {
  MatSliderLabelPipe,
  transformCashAmountToSliderValue,
  transformSliderInputToCashAmount
} from "../pipes/mat-slider-label.pipe";
import {MandateType} from "../constants/application.constants";
import {MarketframeService} from "../services/marketframe.service";
import {concatMap, takeUntil} from "rxjs";
import {MatInput} from "@angular/material/input";
import {MatButton} from "@angular/material/button";
import {MatProgressSpinner} from "@angular/material/progress-spinner";
import {DisplayService} from "../services/display.service";
import {NgxCurrencyDirective} from "ngx-currency";
import {NgxResizeObserverModule} from "ngx-resize-observer";

@Component({
  selector: 'app-i-frame-wrapper',
  standalone: true,
  imports: [
    TranslocoDirective,
    TranslocoPipe,
    MatButtonToggleGroup,
    MatButtonToggle,
    CustomPensionfundComponent,
    StandardStrategyComponent,
    FormsModule,
    AsyncPipe,
    MatSlider,
    MatFormField,
    MatSliderThumb,
    ReactiveFormsModule,
    MatInput,
    MatButton,
    MatProgressSpinner,
    MatSuffix,
    NgClass,
    NgxCurrencyDirective,
    MatError,
    NgxResizeObserverModule
  ],
  providers: [MatSliderLabelPipe, MarketframeService],
  templateUrl: './i-frame-wrapper.component.html',
  styleUrl: './i-frame-wrapper.component.scss'
})
export class IFrameWrapperComponent {

  private sliderRef: ElementRef;
  @ViewChild('sliderRef', { read: ElementRef }) set content(sliderRef: ElementRef) {
    if (sliderRef) {
      this.sliderRef = sliderRef;
      this.setSliderIndicatorLabel();
    }
  }

  private __iframeHeight: number;

  strategyToggleState: 'standard' | 'customPk' = "customPk";

  implementationForm: FormGroup<{
    mandateType: FormControl<MandateType>;
    investmentVolumeSliderControl: FormControl<number>;
    investmentVolumeTextFieldControl: FormControl<number>;
  }>;


  constructor(protected slp: MatSliderLabelPipe,
              public readonly ds: DisplayService,
              protected mfs: MarketframeService,
              private ts: TranslocoService,
              private renderer: Renderer2
  ) {

    this.implementationForm = new FormGroup({
      investmentVolumeSliderControl: new FormControl<number>(transformCashAmountToSliderValue(this.mfs.marketPlaceInputData.implementation.investmentVolume), {nonNullable: true}),
      investmentVolumeTextFieldControl: new FormControl<number>(this.mfs.marketPlaceInputData.implementation.investmentVolume, {nonNullable: true, validators: [Validators.min(50000), Validators.max(20000000)]}),
      mandateType: new FormControl<MandateType>(this.mfs.marketPlaceInputData.implementation.mandateType, {nonNullable: true})
    }, [Validators.required]);

    this.implementationForm.controls.investmentVolumeSliderControl.valueChanges
      .pipe(
        takeUntil(this.mfs.teardownNotifier)
      )
      .subscribe((value: number) => {
        const sliderValue = transformSliderInputToCashAmount(value);
        this.implementationForm.controls.investmentVolumeTextFieldControl.patchValue(sliderValue, {emitEvent: false});
      });
    this.implementationForm.controls.investmentVolumeTextFieldControl.valueChanges
      .pipe(
        takeUntil(this.mfs.teardownNotifier)
      )
      .subscribe((value: number) => {
        const chfValue = transformCashAmountToSliderValue(value);
        this.implementationForm.controls.investmentVolumeSliderControl.patchValue(chfValue, {emitEvent: false});
      });

  }

  handleHostResize(event: ResizeObserverEntry): void {
    const roundedHeight = Math.ceil(event.contentRect.height);
    if (roundedHeight !== this.__iframeHeight) {
      this.__iframeHeight = roundedHeight;
      this.mfs.sendMessageToIFrameParent({type: 'height', data: roundedHeight});
    }
  }

  setSliderIndicatorLabel() {

    // constructs and appends the HTMLSpanElements for the indicator on the mat-slider where 1 Mio. CHF is and the according label above
    const setSliderMillionIndicatorAndLabel = (): {labelSpan: HTMLSpanElement, indicatorSpan: HTMLSpanElement} => {
      const labelSpan = this.renderer.createElement('span');
      const content = this.renderer.createText(this.ts.translate('wrapper.valueIndicatorStep'));
      this.renderer.addClass(labelSpan, 'sliderCustomLabel');
      this.renderer.appendChild(labelSpan, content);
      this.renderer.appendChild(this.sliderRef.nativeElement, labelSpan);

      const indicatorSpan = this.renderer.createElement('span');
      this.renderer.addClass(indicatorSpan, 'sliderCustomIndicator');
      this.renderer.appendChild( this.sliderRef.nativeElement , indicatorSpan);

      return {labelSpan, indicatorSpan};
    };

    let labelSpan = setSliderMillionIndicatorAndLabel().labelSpan;
    this.ts.langChanges$
      .pipe(
        concatMap((lang: string) => this.ts.selectTranslate('wrapper.valueIndicatorStep', {}, lang))
      )
      .subscribe((translatedLabel: string) => {
        labelSpan.textContent = translatedLabel;
      });
    this.ds.dimension.subscribe((val) => {
      if (val >= this.ds.DisplayDimension.MD && !this.sliderRef.nativeElement.querySelector('.sliderMillionLabel') ) {
        labelSpan = setSliderMillionIndicatorAndLabel().labelSpan;
      }
    });
  }

  onSubmit() {
    this.implementationForm.markAllAsTouched();
    if (this.implementationForm.valid) {
      this.mfs.onNavigateEconomicoMarketPlace(
        this.implementationForm.controls.mandateType.value,
        this.implementationForm.controls.investmentVolumeTextFieldControl.value
      );
    }
  }

  protected readonly MandateType = MandateType;
}
