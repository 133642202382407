//  Compare labels with each property name of CategoryToColor Enum
//  If it matches the color from the enum will be passed to the doughnutColors List
import {inject} from "@angular/core";
import {PlatformService} from "../services/platform.service";
import {Context} from "chartjs-plugin-datalabels";
import {ArcElement, Chart, Plugin, Point} from "chart.js";
import {isArray} from "chart.js/helpers";

export function matchLabelToCategoryToColorEnum(chartlabels: string[], colors: ChartColors | undefined): {doughnutColors: string[], doughnutTextColors: string[]} {
  const doughnutColors: string[] = [];
  const doughnutTextColors: string[] = [];
  chartlabels.forEach((label: string) => {
    if (label.includes('HEDGED')) {
      label = label.replace('_HEDGED', '');
    }
    const categoryColor = CategoryToColor[label as keyof typeof CategoryToColor];
    if (colors) {
      doughnutColors.push(colors[categoryColor].color);
      doughnutTextColors.push(colors[categoryColor].contrast);
    }
  });

  return {doughnutColors: doughnutColors, doughnutTextColors: doughnutTextColors};
}

export function getColorForCategory(assetClass: string, colors: ChartColors | undefined): string | undefined {
  if (assetClass.includes('HEDGED')) {
    assetClass = assetClass.replace('_HEDGED', '');
  }
  const categoryColor = CategoryToColor[assetClass as keyof typeof CategoryToColor];
  return colors ? colors[categoryColor].color : undefined;
}

enum CategoryToColor {
  CASH_CHF = 'accentColoursThreeHundred',
  OBLIGATIONEN_CHF = 'primaryColoursFiveHundred',
  OBLIGATIONEN_CHF_INLAND = 'primaryColoursSevenHundred',
  OBLIGATIONEN_CHF_AUSLAND = 'primaryColoursNineHundred',
  OBLIGATIONEN_FW = 'primaryColoursOneHundred',
  OBLIGATIONEN_USD = 'primaryColoursTwoHundred',
  OBLIGATIONEN_EUR = 'primaryColoursFourHundred',
  AKTIEN_SCHWEIZ = 'secondaryColoursFiveHundred',
  AKTIEN_WELT_EX_SCHWEIZ_DM = 'secondaryColoursOneHundred',
  AKTIEN_NORDAMERIKA = 'secondaryColoursTwoHundred',
  AKTIEN_EUROPA_EX_SCHWEIZ = 'secondaryColoursFourHundred',
  AKTIEN_PAZIFIK = 'secondaryColoursSevenHundred',
  // eslint-disable-next-line @typescript-eslint/no-duplicate-enum-values
  AKTIEN_WELT_EM = 'accentColoursThreeHundred',
  IMMOBILIEN_SCHWEIZ = 'accentColourNineHundred',
  IMMOBILIEN_WELT = 'accentColoursFiveHundred',
}

export type ChartColors = {
  primaryColoursOneHundred: ColorPair;
  primaryColoursTwoHundred: ColorPair;
  primaryColoursThreeHundred: ColorPair;
  primaryColoursFourHundred: ColorPair;
  primaryColoursFiveHundred: ColorPair;
  primaryColoursSevenHundred: ColorPair;
  primaryColoursNineHundred: ColorPair;
  secondaryColoursOneHundred: ColorPair;
  secondaryColoursTwoHundred: ColorPair;
  secondaryColoursThreeHundred: ColorPair;
  secondaryColoursFourHundred: ColorPair;
  secondaryColoursFiveHundred: ColorPair;
  secondaryColoursSevenHundred: ColorPair;
  secondaryColoursNineHundred: ColorPair;
  accentColoursThreeHundred: ColorPair;
  accentColoursFiveHundred: ColorPair;
  accentColourNineHundred: ColorPair;
}

type ColorPair = {
  color: string;
  contrast: string;
}

export function getChartColors(): ChartColors {
  const ps = inject(PlatformService);
  if (ps.isBrowser && document) {
    const styleDeclaration = getComputedStyle(document.body);
    return {
      primaryColoursOneHundred: {
        color: styleDeclaration.getPropertyValue('--primary-colour-100'),
        contrast: '#000000'
      },
      primaryColoursTwoHundred: {
        color: styleDeclaration.getPropertyValue('--primary-colour-200'),
        contrast: '#000000'
      },
      primaryColoursThreeHundred: {
        color: styleDeclaration.getPropertyValue('--primary-colour-300'),
        contrast: '#000000'
      },
      primaryColoursFourHundred: {
        color: styleDeclaration.getPropertyValue('--primary-colour-400'),
        contrast: '#ffffff'
      },
      primaryColoursFiveHundred: {
        color: styleDeclaration.getPropertyValue('--primary-colour-500'),
        contrast: '#ffffff'
      },
      primaryColoursSevenHundred: {
        color: styleDeclaration.getPropertyValue('--primary-colour-700'),
        contrast: '#ffffff'
      },
      primaryColoursNineHundred: {
        color: styleDeclaration.getPropertyValue('--primary-colour-900'),
        contrast: '#ffffff'
      },
      secondaryColoursOneHundred: {
        color: styleDeclaration.getPropertyValue('--secondary-colour-100'),
        contrast: '#000000'
      },
      secondaryColoursTwoHundred: {
        color: styleDeclaration.getPropertyValue('--secondary-colour-200'),
        contrast: '#000000'
      },
      secondaryColoursThreeHundred: {
        color: styleDeclaration.getPropertyValue('--secondary-colour-300'),
        contrast: '#000000'
      },
      secondaryColoursFourHundred: {
        color: styleDeclaration.getPropertyValue('--secondary-colour-400'),
        contrast: '#000000'
      },
      secondaryColoursFiveHundred: {
        color: styleDeclaration.getPropertyValue('--secondary-colour-500'),
        contrast: '#000000'
      },
      secondaryColoursSevenHundred: {
        color: styleDeclaration.getPropertyValue('--secondary-colour-700'),
        contrast: '#000000'
      },
      secondaryColoursNineHundred: {
        color: styleDeclaration.getPropertyValue('--secondary-colour-900'),
        contrast: '#ffffff'
      },
      accentColoursThreeHundred: {
        color: styleDeclaration.getPropertyValue('--accent-colour-300'),
        contrast: '#000000'
      },
      accentColoursFiveHundred: {
        color: styleDeclaration.getPropertyValue('--accent-colour-500'),
        contrast: '#ffffff'
      },
      accentColourNineHundred: {
        color: styleDeclaration.getPropertyValue('--accent-colour-900'),
        contrast: '#ffffff'
      }
    };
  }
  return undefined;
}

export function splitWordsForChartLabel(context: Context) {
  const label = context.chart.data.labels[context.dataIndex] as string;
  const meta = context.chart.getDatasetMeta(0).data[0] as ArcElement;
  const textMetrics: TextMetrics = context.chart.ctx.measureText(context.chart.data.labels[context.dataIndex] as string);
  const textWidth: number = textMetrics.width;
  const outerRadius = meta.outerRadius;
  const availableWidthForLabel = (context.chart.width / 2) - (outerRadius * 1.5);

  if (textWidth > availableWidthForLabel && label) {
    const splittedLabel = label.split(" ");
    const linesList: string[] = [];
    let linesListIndex = 0;
    let countLenght = 0;

    splittedLabel.forEach((word, index) => {
      const textWidthSplittedItem: number = context.chart.ctx.measureText(word + " ").width;

      if ((countLenght + textWidthSplittedItem) > availableWidthForLabel && linesListIndex < 2) {
        linesListIndex++;
        countLenght = 0;
        linesList.push((word + " "));
      } else if ((countLenght + textWidthSplittedItem) <= availableWidthForLabel || index === 0) {
        if (linesList[linesListIndex] == null || linesList[linesListIndex] == undefined) {
          linesList.push((word + " "));
        } else {
          linesList[linesListIndex] += (word + " ");
        }
        countLenght += textWidthSplittedItem;
      }
    });
    return linesList;
  }

  //  Looks better if a label which has a length >= 5 is automatically wrapped into 2 rows even if the space is enough for just one row
  else if (label?.split(" ").length >= 5) {
    const splitted = label.split(" ");
    let resultLabel = "";
    splitted.forEach((word, index) => index === 1 ? resultLabel += word + "\n" : resultLabel += word + " ");
    return resultLabel;
  }
  return label;
}

type labelMetaUnion = {
  $datalabels: {
    $layout: {
      _visible: boolean | number,
      _box: {
        _rect: {
          x: number,
          y: number,
          h: number,
          w: number
        }
      }
    }
  }[]
}

export const doughnutCenterLabelPlugin: Plugin<'doughnut'> = {
  id: 'doughnutCenterLabelPlugin',
  afterDatasetsDraw(chart: Chart<'doughnut'>) {
    //Get context from the chart-canvas & decimalPipe
    const ctx = chart.ctx;
    const chartArea = chart.chartArea;
    // Calculate centre position for the label
    const centerX = (chartArea.left + chartArea.right) / 2;
    const centerY = (chartArea.top + chartArea.bottom) / 2;
    const title: string | string[] | undefined = chart.options?.plugins?.title?.text;
    const subtitle: string | string[] | undefined = chart.options?.plugins?.subtitle?.text;
    //Define the bold text
    const bold = Array.isArray(title) ? title : ([title || '']);
    //default fontsize for bold text
    const boldFontSize = 24;
    //Define label style for the bold text
    ctx.textAlign = 'center';
    ctx.textBaseline = 'middle';
    ctx.fillStyle = 'black';
    ctx.font = '300 ' + boldFontSize + 'px Rokkitt Regular';
    ctx.save();
    // Draw the label into the chart-canvas
    bold.forEach((value: string, index: number) => {
      //For every 10 types
      if (value.length > 10) {
        const fontSize = boldFontSize - Math.floor(value.length / 10) * 4;
        ctx.font = '300 ' + fontSize + 'px Rokkitt Regular';
        ctx.save();
      }
      //If only bold title, then subtitle is an empty string --> center bold title
      if (subtitle === '') {
        if(isArray((bold)) && bold.length > 1){
          if(index === 0){
            ctx.fillText(value, centerX, centerY -10);
          }
          else{
            ctx.fillText(value, centerX, centerY + 10);
          }
        }
        else{
          ctx.fillText(value, centerX, centerY);
        }
        return;
      }
      //If there is a subtitle, then the bold title has to be placed further up
      if (index === 0) {
        ctx.fillText(value, centerX, centerY - 14);
      } else {
        ctx.fillText(value, centerX, centerY - 20 * (index + 1));
      }
    });
    ctx.restore();
    //Define the thin text
    const thin = Array.isArray(subtitle) ? subtitle : ([subtitle || '']);
    //Define label style for the text
    ctx.textAlign = 'center';
    ctx.textBaseline = 'middle';
    ctx.fillStyle = 'black';
    ctx.font = '400 16px Rokkitt Regular';
    ctx.save();
    thin.forEach((value: string, index: number) => {
      ctx.fillText(value, centerX, centerY + (14 * (index + 1)));
    });
    ctx.restore();
  }
};


export const doughnutLabelStrokePlugin: Plugin<'doughnut'> = {
  id: 'doughnutCenterLabelPlugin',
  afterDatasetsDraw(chart: Chart<'doughnut'>) {

    chart.data.labels.forEach((label, index) => {
      const meta = chart.getDatasetMeta(0).data[index] as ArcElement & labelMetaUnion;

      if(meta.$datalabels[0].$layout._visible === true || meta.$datalabels[0].$layout._visible === 1){
        const labelRectMeta = meta.$datalabels[0].$layout._box._rect;
        const outerRadius = meta.outerRadius;
        const startAngle = meta.startAngle;
        const endAngle = meta.endAngle;
        const middleAnglePoint = (startAngle + endAngle) / 2;

        const rectPointsList: { x: number, y: number }[] = [];

        const centerPoint: Point = {
          x: meta.x,
          y: meta.y
        };
        const centerPointLabel: Point = {
          x: centerPoint.x + Math.cos(middleAnglePoint) * outerRadius,
          y: centerPoint.y + Math.sin(middleAnglePoint) * outerRadius
        };

        const topPoint = {
          x: labelRectMeta.x + (labelRectMeta.w / 2),
          y: labelRectMeta.y
        };
        const bottomPoint = {
          x: labelRectMeta.x + (labelRectMeta.w / 2),
          y: labelRectMeta.y + labelRectMeta.h
        };
        const leftPoint = {
          x: labelRectMeta.x,
          y: labelRectMeta.y + (labelRectMeta.h / 2)
        };
        const rightPoint = {
          x: labelRectMeta.x + labelRectMeta.w,
          y: labelRectMeta.y + (labelRectMeta.h / 2)
        };

        if (bottomPoint.y - (labelRectMeta.h /2 ) > 103 && bottomPoint.y - (labelRectMeta.h /2 ) < 253){
          rectPointsList.push(leftPoint, rightPoint);
        }
        else{
          rectPointsList.push(topPoint, bottomPoint);
        }

        // Calculate the closest point from an item of rectPointsList to (centerPointLabel.x | centerPointLabel.y)
        const closest = rectPointsList.reduce((a, b) => Math.sqrt(Math.pow(centerPointLabel.x - a.x, 2) + Math.pow(centerPointLabel.y - a.y, 2)) < Math.sqrt(Math.pow(centerPointLabel.x - b.x, 2) + Math.pow(centerPointLabel.y - b.y, 2)) ? a : b);

        chart.ctx.beginPath();
        chart.ctx.moveTo(closest.x, closest.y);
        chart.ctx.lineTo(centerPointLabel.x, centerPointLabel.y);
        chart.ctx.strokeStyle = meta.options.backgroundColor;
        chart.ctx.stroke();
      }
    });
  }
};
